import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "@fontsource/barlow/700.css";
import "@fontsource/inter";

import Header from "./Header";

const GlobalStyles = createGlobalStyle`
  html, body {
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Barlow', sans-serif;
  }
`;

const theme = {
  colors: {
    orange: "#ff3c3c",
  },
  fonts: {
    secondary: "'Barlow', sans-serif",
  },
};

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {/* <Header /> */}
      {children}
    </ThemeProvider>
  );
};

export default Layout;
